<script lang="ts">
  import classnames from 'classnames';
  import { omit } from '$lib/utils/object';
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  // Default Props
  export let color: 'dark' | 'light' = 'dark';
  export let iconPos: 'start' | 'end' = 'end';
  export let expanded = false;
  export let disabled = false;
  export let click: () => {} = null;
  /** -- */
  export let icon: string = 'chevronRight';
  export let animation: 'up' | 'down' | 'horizontal' = 'horizontal';
  export let href = '#';

  const classNames = classnames($$props.class, {
    link: true,
    'link-icon-end': iconPos === 'end',
    'link-icon-start': iconPos === 'start',
    'c-dark': color === 'dark',
    'c-light': color === 'light',
  });
  const attributes = omit($$restProps, 'class');
</script>

{#if click}
  <a
    {href}
    class={classNames}
    on:click|preventDefault={click ? click() : () => {}}
    class:expanded
    class:disabled
    {...attributes}
  >
    <span class="link-text">
      <slot />
    </span>
    <span
      class={classnames('link-icon', {
        horizontal: animation === 'horizontal',
        up: animation == 'up',
        down: animation == 'down',
      })}
    >
      <Icon icon={icon} />
    </span>
  </a>
{:else}
  <a {href} class={classNames} class:expanded class:disabled {...attributes}>
    <span class="link-text">
      <slot />
    </span>
    <span
      class={classnames('link-icon', {
        horizontal: animation === 'horizontal',
        up: animation == 'up',
        down: animation == 'down',
      })}
    >
      <Icon icon={icon} />
    </span>
  </a>
{/if}

<style type="text/scss" lang="scss" scoped>
  .link {
    @apply appearance-none;
    @apply inline-flex flex-shrink-0 items-center self-center;
    @apply uppercase font-semibold;
    @apply text-sm md:text-base whitespace-nowrap no-underline tracking-wide;
    @apply border-none;
    @apply p-0;
    @apply select-none;
    &:not(.disabled) {
      @apply cursor-pointer;
      @apply transition-colors ease-in-out duration-300;
    }

    &.disabled {
      @apply text-gray-900 text-opacity-50;
      @apply pointer-events-none;
      @apply cursor-default;
    }

    &.expanded {
      &:before {
        content: '';
        @apply absolute inset-0;
      }
    }
  }

  .link-text {
    @apply flex items-center;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: center;
  }

  /* COLORS */
  .c-dark {
    @apply text-gray-900;
    /* Hover */
    &:not(.disabled) {
      @apply hover:text-petrol;
    }
  }

  .c-light {
    @apply text-white;
  }

  /* ICON ALIGNMENT */

  .link-icon {
    @apply inline-flex;
    @apply w-6 h-6;
    @apply transition-transform ease-in-out duration-300;
  }

  .link-icon-end {
    .link-text + .link-icon {
      @apply ml-1;
    }

    &:hover:not(.disabled) {
      .link-icon.horizontal {
        @apply transform translate-x-1;
      }
      .link-icon.down {
        @apply transform translate-y-1;
      }

      .link-icon.up {
        @apply transform -translate-y-1;
      }
    }
  }

  .link-icon-start {
    @apply flex-row-reverse;

    .link-text + .link-icon {
      @apply mr-1;
    }

    &:hover:not(.disabled) {
      .link-icon {
        @apply transform -translate-x-1;
      }
    }
  }
</style>
